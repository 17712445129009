import Vue from 'vue'
import { loadProgressBar } from 'axios-progress-bar'
import VTooltip from 'v-tooltip'
import { Globals, Toasters } from '@technologyadvice/relay-frontend'
import readableTypes from '@/plugins/readableTypes'
import GDPDomain from '@plugins/gdpDomain'
import { messages } from 'vee-validate/dist/locale/en.json'
import {
    ValidationObserver, ValidationProvider, extend, setInteractionMode,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { createPinia, PiniaVuePlugin } from 'pinia'
import App from './App.vue'
import router from './router'
import { store } from './store'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

setInteractionMode('aggressive')
// Vee-Validate Rules
Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule], // assign message
    });
})

extend('url', {
    validate(value) {
        // eslint-disable-next-line max-len
        const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/
        return urlRegex.test(value.toLowerCase())
    },
    message: "Must be a fully-valid URL, for example, 'https://www.your-website.com/landing-page'",
})

extend('password', {
    validate(value) {
        const hasUppercaseLetter = /[A-Z]/.test(value);
        const hasLowercaseLetter = /[a-z]/.test(value);
        const hasNumber = /\d/.test(value);
        const hasMinCharacters = value.length >= 12;
        const doesNotContainSpaces = !/\s/.test(value);

        return hasUppercaseLetter
            && hasLowercaseLetter
            && hasNumber
            && hasMinCharacters
            && doesNotContainSpaces;
    },
    message: 'Password must be at least 12 characters and contain mixed case letters, numbers, and no spaces.',
})

Vue.use(VTooltip)
Vue.use(Globals)
Vue.use(Toasters)
Vue.use(readableTypes)
Vue.use(PiniaVuePlugin)
Vue.use(GDPDomain)

const pinia = createPinia()

Vue.config.productionTip = false

new Vue({
    router,
    store,
    pinia,
    render: h => h(App),
}).$mount('#app')

loadProgressBar()
