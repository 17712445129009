<template>
    <ValidationObserver
        ref="observer"
        v-slot="{ handleSubmit, invalid }">
        <div
            v-for="input in inputs"
            :key="input.name"
            class="form-group">
            <ValidationProvider
                v-slot="{ errors }"
                :ref="input.name"
                :rules="input.rules"
                :name="input.label"
                mode="eager">
                <label class="card__info-label mb-1 required">{{ input.label }}</label>
                <input
                    v-model="formData[input.name]"
                    :name="input.label"
                    class="form-control"
                    :class="{ 'is-invalid' : errors.length }"
                    :type="input.type">
                <MessageValidationError
                    v-if="errors.length"
                    :msg="errors[0]">
                </MessageValidationError>
            </ValidationProvider>
        </div>

        <Checkbox
            v-model="formData.emailOptin"
            class="signup__optin"
            label="I would like to receive updates about products, services, and special offers from TechnologyAdvice">
        </Checkbox>

        <button
            v-gtm:click="[
                {
                    event: 'company_setup_step_submit',
                    eventData: {
                        email: formData['email'],
                    },
                },
            ]"
            class="btn btn-primary w-100 mt-4"
            type="button"
            :disabled="invalid"
            @click="handleSubmit(next)">
            Next: Setup Your Account
        </button>

        <div class="mt-3 text-center signup__login-subtext">
            Already have an account?
            <router-link
                :to="{ name: 'loginV2' }"
                class="font-weight-bold">
                Login
            </router-link>.
        </div>
    </ValidationObserver>
</template>

<script>
import {
    Checkbox,
    MessageValidationError,
} from '@technologyadvice/relay-frontend'

export default {
    components: {
        Checkbox,
        MessageValidationError,
    },
    props: {
        formData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            inputs: [
                {
                    label: 'First Name',
                    name: 'first_name',
                    type: 'text',
                    rules: 'required',
                },
                {
                    label: 'Last Name',
                    name: 'last_name',
                    type: 'text',
                    rules: 'required',
                },
                {
                    label: 'Company Name',
                    name: 'company_name',
                    type: 'text',
                    rules: 'required',
                },
                {
                    label: 'Website',
                    name: 'company_website',
                    type: 'text',
                    rules: 'required|url',
                },
            ],
        }
    },
    methods: {
        next() {
            this.$emit('step');
        },
    },
};
</script>
