var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"divider my-5"}),_c('div',[_c('div',{staticClass:"mt-3 text-center signup__login-subtext"},[_vm._v(" You can "),_c('button',{directives:[{name:"gtm",rawName:"v-gtm:click",value:([
                    {
                        event: 'create_account_resend_email',
                        eventData: {
                            email: _vm.email,
                        },
                    },
                ]),expression:"[\n                    {\n                        event: 'create_account_resend_email',\n                        eventData: {\n                            email: email,\n                        },\n                    },\n                ]",arg:"click"}],staticClass:"btn btn-link text-link",attrs:{"type":"button","aria-label":"resend verification email"},on:{"click":_vm.resendVerificationEmail}},[_vm._v(" resend the email ")]),_vm._v(" or sign in with Google instead. ")]),_c('a',{directives:[{name:"gtm",rawName:"v-gtm:click",value:([
                {
                    event: 'create_account',
                    eventData: {
                        email: _vm.email,
                        type: 'google',
                        source: 'resend email page',
                    },
                },
            ]),expression:"[\n                {\n                    event: 'create_account',\n                    eventData: {\n                        email: email,\n                        type: 'google',\n                        source: 'resend email page',\n                    },\n                },\n            ]",arg:"click"}],staticClass:"google-login__btn d-flex mx-auto align-items-center btn text-secondary px-3 mt-2",attrs:{"href":`/accounts/bff/redirect?${_vm.redirectQueryParam}`}},[_c('span',{staticClass:"mx-auto"},[_c('img',{staticClass:"google-login__logo",attrs:{"alt":"Google Logo","src":_vm.googleLogo}}),_c('span',{staticClass:"ml-1"},[_vm._v(" Continue With Google ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h4',[_vm._v("Thank you for signing up!")]),_c('div',{staticClass:"mt-4 mb-1 subtitle"},[_vm._v(" Check your Email. ")]),_c('div',[_vm._v(" We've sent a verification link to your email. Click the link to activate your new account. ")])])
}]

export { render, staticRenderFns }