const isDomainGDP = () => window.location.hostname.includes('globaldemandpartners.com')

export default {
    install(Vue) {
        Vue.prototype.$_isDomainGDP = isDomainGDP()

        if (isDomainGDP()) {
            document.documentElement.setAttribute('data-theme', 'gdp')
        }
    },
}

export { isDomainGDP }
