<template>
    <div>
        <div class="text-center">
            <h4>Thank you for signing up!</h4>

            <div class="mt-4 mb-1 subtitle">
                Check your Email.
            </div>

            <div>
                We've sent a verification link to your email. Click the link to activate your new account.
            </div>
        </div>

        <div class="divider my-5" />

        <div>
            <div class="mt-3 text-center signup__login-subtext">
                You can
                <button
                    v-gtm:click="[
                        {
                            event: 'create_account_resend_email',
                            eventData: {
                                email: email,
                            },
                        },
                    ]"
                    type="button"
                    aria-label="resend verification email"
                    class="btn btn-link text-link"
                    @click="resendVerificationEmail">
                    resend the email
                </button>
                or sign in with Google instead.
            </div>
            <a
                v-gtm:click="[
                    {
                        event: 'create_account',
                        eventData: {
                            email: email,
                            type: 'google',
                            source: 'resend email page',
                        },
                    },
                ]"
                :href="`/accounts/bff/redirect?${redirectQueryParam}`"
                class="google-login__btn d-flex mx-auto
                align-items-center btn text-secondary px-3 mt-2">
                <span class="mx-auto">
                    <img
                        alt="Google Logo"
                        :src="googleLogo"
                        class="google-login__logo">
                    <span class="ml-1">
                        Continue With Google
                    </span>
                </span>
            </a>
        </div>
    </div>
</template>

<script>
import api from '@api/accounts';
import googleLogo from '../../assets/imgs/logo/google-logo.svg'

export default {
    props: {
        email: {
            type: String,
            required: true,
        },
    },
    computed: {
        redirectQueryParam() {
            return 'redirect=/clicks/products/create'
        },
    },
    data() {
        return {
            googleLogo,
        }
    },
    methods: {
        resendVerificationEmail() {
            api.sendInvite({ email: this.email })
                .then(() => {
                    this.$_toasterSuccess('Invite resent!')
                })
                .catch(() => {
                    this.$_toasterDanger('Could not resend invite. Please try again or contact support.')
                })
        },
    },
};
</script>

<style lang="scss" scoped>
.google-login {
    &__btn {
        width: 100%;
        border: 1px solid #d9dbf1;
        background-color: white;
        border-radius: 25px;
        color: #aba5af;
        padding-inline: 1rem;

        &:hover {
            background-color: darken(white, 5%);
        }
    }

    &__logo {
        width: 2rem;
        height: 2rem;
    }
}

.divider {
    height: 1px;
    background-color: #ddd;
}

.subtitle {
    font-size: 1.25rem
}

.text-link {
    font-size: var(--baseFontSize);
    vertical-align: baseline;
}
</style>
