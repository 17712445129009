import axios from '@axios' //eslint-disable-line

export default {
    login(email, password, redirect = '', recaptcha = '') {
        return axios.post('/auth/login', {
            email,
            password,
            redirect,
            recaptcha,
        })
    },
    verifyEmail(token) {
        return axios.put(`/auth/verify-email/${token}`)
    },
    logout() {
        return axios.post('/auth/logout')
    },
    me() {
        return axios.get('/auth/me')
    },
    getUsersToImpersonate() {
        return axios.get('/auth/impersonate/users')
    },
    impersonateUser(uuid) {
        return axios.post('/auth/impersonate', {
            user_uuid: uuid,
        })
    },
    returnToOriginalUser() {
        return axios.post('/auth/impersonate/logout')
    },
    switchAccount(userUuid, accountUuid) {
        return axios.post('/auth/switch-account', {
            user_uuid: userUuid,
            account_uuid: accountUuid,
        })
    },
    accountSignup(data) {
        return axios.post('/auth/sign-up', data)
    },
}
