<template>
    <div
        v-cloak
        class="container-fluid h-100 loginV2__bkg">
        <div class="d-flex justify-content-center align-items-center h-100">
            <div class="loginV2">
                <div class="mb-4">
                    <img
                        :alt="$_isDomainGDP ? 'GDP Logo' : 'Traction Logo'"
                        :src="$_isDomainGDP ? logoGDP : logo"
                        class="loginV2__logo">
                </div>

                <form class="loginV2__container">
                    <h5 class="h4 forgot-password__heading mb-3">
                        Forgot Password
                    </h5>

                    <div class="forgot-password__info mb-4">
                        <small>
                            Please enter your email address, and we will send you
                            instructions on how to reset your password.
                        </small>
                    </div>

                    <FormForgotPassword></FormForgotPassword>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import logo from '@technologyadvice/relay-frontend/src/assets/brand-logo-refresh-white-text.svg'
import logoGDP from '@technologyadvice/relay-frontend/src/assets/gdp-logo.png'
import FormForgotPassword from '@/components/common/FormForgotPassword.vue'

export default {
    components: {
        FormForgotPassword,
    },
    data() {
        return {
            logo,
            logoGDP,
        }
    },
}
</script>
