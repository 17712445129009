<template>
    <div>
        <div class="text-center">
            <div class="d-flex justify-content-between align-items-center">
                <button
                    v-if="!isFirstStep"
                    type="button"
                    class="btn btn-link col-2 back-button col-auto"
                    aria-label="Back"
                    @click="$router.back()">
                    <i
                        aria-hidden="true"
                        class="fa fa-chevron-left" /> Back
                </button>
                <div
                    class="brand-secondary-color text-center"
                    :class="isFirstStep ? 'col-12' : 'col-8'">
                    Step {{ activeStepIndex+1 }} of {{ totalSteps }}
                </div>
                <!-- spacer to keep the step count centered -->
                <div
                    v-if="!isFirstStep"
                    class="col-2" />
            </div>
            <h4>{{ currentStep.title }}</h4>
        </div>

        <div>
            <keep-alive>
                <router-view
                    :form-data="formData"
                    :validation-errors="validationErrors"
                    @step="onStep"
                    @success="onSuccess">
                </router-view>
            </keep-alive>
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        steps: {
            type: Array,
            required: true,
        },
        formData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            activeStepIndex: 0,
            validationErrors: {},
        }
    },
    computed: {
        totalSteps() {
            return this.steps.length
        },
        maxStepIndex() {
            return this.totalSteps - 1
        },
        currentStep() {
            return this.steps[this.activeStepIndex]
        },
        isFirstStep() {
            return this.activeStepIndex === 0
        },
    },
    watch: {
        $route(to) {
            this.updateActiveStep(to.name)
        },
    },
    methods: {
        onStep(stepIndex = null) {
            this.activeStepIndex = Math.min(stepIndex ?? (this.activeStepIndex + 1), this.maxStepIndex)
            const stepRouteName = this.currentStep.routeName
            this.$router.push({ name: stepRouteName })
        },
        onSuccess() {
            this.$emit('success')
        },
        updateActiveStep(routeName) {
            const stepIndex = this.steps.findIndex(step => step.routeName === routeName)
            if (stepIndex !== -1) {
                this.activeStepIndex = stepIndex
            }
        },
    },
    created() {
        this.updateActiveStep(this.$route.name)
    },
}
</script>

<style lang='scss' scoped>
.brand-secondary-color {
    color: var(--brandSecondary)
}

.back-button {
    font-size: var(--baseFontSize)
}
</style>
