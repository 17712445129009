<template>
    <div>
        <LoadingSpinner v-if="isLoading"></LoadingSpinner>

        <CardError v-else-if="loadError"></CardError>

        <template v-else>
            <ViewHeader
                class="mb-4"
                :heading="`${ viewedUser.first_name} ${ viewedUser.last_name}`"
                sub-heading="View and update profile information">
            </ViewHeader>
            <div class="row">
                <div class="col-12 col-lg-9">
                    <CardUserInfo
                        v-model="viewedUser"
                        :can-manage-user="canManageViewedUser">
                    </CardUserInfo>

                    <CardSocialIdentities
                        v-if="isViewedUser && !$_isDomainGDP"
                        :user-uuid="user_uuid"
                        :is-viewed-user="isViewedUser">
                    </CardSocialIdentities>

                    <CardChangePassword
                        v-if="canManageViewedUserPasword">
                    </CardChangePassword>
                </div>
                <div class="col-12 col-lg-3">
                    <CardAvatar
                        v-model="viewedUser"
                        :can-manage-user="canManageViewedUserStatus">
                    </CardAvatar>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import CardAvatar from '@components/users/edit/CardAvatar.vue'
import CardChangePassword from '@components/users/edit/CardChangePassword.vue'
import CardUserInfo from '@components/users/edit/CardUserInfo.vue'
import CardSocialIdentities from '@components/users/edit/CardSocialIdentities.vue'
import { mapGetters, mapActions } from 'vuex'
import {
    LoadingSpinner,
    CardError,
    ViewHeader,
} from '@technologyadvice/relay-frontend'

export default {
    name: 'ViewUser',
    components: {
        CardAvatar,
        CardError,
        CardChangePassword,
        LoadingSpinner,
        CardUserInfo,
        ViewHeader,
        CardSocialIdentities,
    },
    props: {
        user_uuid: { // eslint-disable-line
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters('auth', [
            'canManage',
            'hasScope',
            'userManagedTeams',
        ]),
        ...mapGetters('auth', {
            authUser: 'user',
        }),
        ...mapGetters('users', [
            'getErrorState',
            'getLoadingState',
        ]),
        ...mapGetters('users', {
            viewedUser: 'user',
        }),
        isLoading() {
            return this.getLoadingState('user')
        },
        isViewedUser() {
            return this.authUser.uuid === this.user_uuid
        },
        loadError() {
            return this.getErrorState('user')
        },
        canManageViewedUser() {
            return this.canManage(this.viewedUser)
        },
        canManageViewedUserStatus() {
            return this.canManageViewedUser && !this.isViewedUser
        },
        canManageViewedUserPasword() {
            return this.isManagedAgent || this.isViewedUser
        },
        isManagedAgent() {
            return this.isManagedTdAgent || this.isManagedQaAgent
        },
        isManagedQaAgent() {
            return this.viewedUserHasRole('qa_user') && this.hasScope('manage-qa')
        },
        isManagedTdAgent() {
            return this.viewedUserHasRole('teledemand_agent') && this.hasScope('manage-teledemand-agents')
        },
    },
    watch: {
        user_uuid() {
            this.loadUser(this.user_uuid)
        },
    },
    created() {
        this.loadUser(this.user_uuid)
    },
    methods: {
        ...mapActions('users', [
            'loadUser',
        ]),
        viewedUserHasRole(roleCode) {
            return this.viewedUser.roles.find(role => role.code === roleCode)
        },
    },
}
</script>
